import { useState } from 'react';
import FromContext from './FormDownloadContext';
import FormLayout from './FormLayout';

function FormDownload() {
  const [nlcode, setNlcode] = useState('');
  const [rawData, setRawData] = useState({});
  const [selData, setSelData] = useState([]);

  return (
    <FromContext.Provider value={{
      nlcode,
      setNlcode,
      rawData,
      setRawData,
      selData,
      setSelData
    }}>
      <FormLayout />
    </FromContext.Provider>
  );
}

export default FormDownload;
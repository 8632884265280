import { useContext, useEffect, useState } from 'react';

import transfromNL2XLS from './utils/trasformNL2XLS';
import context from './FormDownloadContext';
import { ExternalLinkIcon } from '@chakra-ui/icons';
import { Button } from '@chakra-ui/react';

const { utils, writeFile } = require('xlsx');
const { useCallback } = require('react');

function ExportSelectedXLS() {
  const { nlcode, rawData, selData } = useContext(context);
  const [isDisabled, setIsDisabled] = useState(true);

  useEffect(() => {
    if (selData?.length) {
      // console.log(selData);
      setIsDisabled(false);
    }
    else {
      setIsDisabled(true);
    }
  }, [selData]);

  const exportFile = useCallback(() => {
    // const ws = utils.aoa_to_sheet(ws_data);

    /* generate worksheet from state */
    const ws = utils.json_to_sheet(transfromNL2XLS({ ...rawData, alienlist: selData }));

    /* create workbook and append worksheet */
    const wb = utils.book_new();
    utils.book_append_sheet(wb, ws, "Sheet1");
    /* export to XLSX */
    writeFile(wb, `${nlcode}.xlsx`);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selData, nlcode, rawData]);

  return (
    <Button
      onClick={exportFile}
      leftIcon={<ExternalLinkIcon />}
      isLoading={false}
      loadingText='Submitting'
      colorScheme='blue'
      variant='outline'
      isDisabled={isDisabled}
    >
      ส่งออกที่เลือก
    </Button>
  );
}

export default ExportSelectedXLS;
import th_address_province from './th_address_province';
import th_address_area from './th_address_area';

function transfromNL2XLS(successData) {
  const { empname, wkaddress, alienlist: als } = successData;
  const { address1, district, area, province, zipcode } = evaluateAddressLine(wkaddress);
  const arr = [];

  for (let i = 0; i < als.length; i++) {
    const al = als[i];
    arr.push({
      "ลำดับ": `${i + 1}`,
      "คำนำหน้านาม": al.alprefixen,
      "ชื่อผู้เอาประกันภัย": al.alnameen,
      "นามสกุลผู้เอาประกันภัย": al.alsnameen,
      "เพศ": al.algender === "1" ? 'M' : al.algender === "2" ? 'F' : '',
      "วันเดือนปีเกิด": al.albdate,
      "อายุ": "",
      "ส่วนสูง": "",
      "น้ำหนัก": "",
      "สัญชาติ": al.alnation,
      "เลขที่ Passoport": "",
      "เลขที่ Workpermit": "",
      "เลขที่ CI": "",
      "เลขที่ใบแทนอนุญาตทำงาน ": al.alcode,
      "ชื่อนายจ้าง": empname,
      "เลขประจำตัว": "",
      "เบอร์โทร": "",
      "Email": "",
      "ที่อยู่": address1,
      "ตำบล": district,
      "อำเภอ": area,
      "จังหวัด": province,
      "รหัสไปรษณีย์": zipcode,
    });
  }

  return arr;
}


/**
 * @param {'M'|'C'|'L'|'V'} str 
 */
// eslint-disable-next-line no-unused-vars
function evaluateCoutryCode(str) {
  switch (str) {
    case "M":
      return "myanmar";
    case "C":
      return "cambodia";
    case "L":
      return "laos";
    case "V":
      return "vietnam";
    default:
      return "";
  }
}

/**
 * @param {string} wkaddres 
 */
function evaluateAddressLine(wkaddres) {
  try {
    let addressArr = wkaddres.split(/\s+/);
    const addressObj = {
      address1: '',
      district: '',
      area: '',
      province: '',
      zipcode: ''
    };
    const resZipcode = parseZipcode(addressArr);
    addressObj.zipcode = resZipcode.zipcode;
    addressArr = resZipcode.addressArr;

    const resProvince = parseProvince(addressArr);
    addressObj.province = resProvince.province;
    addressArr = resProvince.addressArr;

    const resAera = parseArea(addressArr);
    addressObj.area = resAera.area;
    addressArr = resAera.addressArr;

    const resDistrict = parseDistrict(addressArr);
    addressObj.district = resDistrict.district;
    addressArr = resDistrict.addressArr;

    addressObj.address1 = addressArr.join(' ');

    // console.log(addressObj);
    // console.log(addressArr);

    for (const key in addressObj) {
      if (!addressObj[key]) {
        throw new Error('parse address error');
      }
    }
    return addressObj;
  } catch (err) {
    throw err;
  }
}

/**
 * Zipcode
 * - Number 
 * - 5 digit
 * @param {string[]} address 
 */
function parseZipcode(addressArr) {
  let zipcode = '';
  // try with last index 
  const cursor1 = addressArr.length - 1;
  const lastIdx = addressArr[cursor1];
  if (!isNaN(Number(lastIdx)) && lastIdx.length === 5) {
    zipcode = lastIdx;
    // addressArr.splice(cursor1, 1);
    // NOTE: cut off everything after this
    addressArr.splice(cursor1);
  } else {
    // try with findIndex
    const cursor2 = addressArr.findIndex(x => x.length === 5 && !isNaN(Number(x)));
    if (cursor2 > -1) {
      zipcode = addressArr[cursor2];
      // addressArr.splice(cursor2, 1);
      // NOTE: cut off everything after this
      addressArr.splice(cursor2);
    }
  }
  if (zipcode) {
    const nbZipcode = Number(zipcode);
    if (nbZipcode >= 10100 && nbZipcode <= 96220) {
      return { zipcode, addressArr };
    }
  }
  throw new Error('parse zipcode error');
}

/**
 * Provicnce 77
 * - includes จังหวัด or กรุงเทพ or ภูเก็ต
 * @param {string[]} addressArr 
 */
function parseProvince(addressArr) {
  let province = '';

  // method 1 test with regex 
  const regex1 = /^จังหวัด/;
  const regex2 = /^กรุงเทพ/;
  const regex3 = /^จังหวัดกรุงเทพ/;
  const regex4 = /^ภูเก็ต/;
  const regex5 = /^จังหวัดภูเก็ต/;
  function testRegex(str) {
    if (regex1.test(str) ||
      regex2.test(str) ||
      regex3.test(str) ||
      regex4.test(str) ||
      regex5.test(str)
    ) {
      return true;
    }
    return false;
  }

  const cursor1 = addressArr.findIndex(x => testRegex(x));
  if (cursor1 > -1) {
    province = addressArr[cursor1].replace('จังหวัด', '');
    // addressArr.splice(cursor1, 1);
    // NOTE: cutoff everything after this
    addressArr.splice(cursor1);
  }

  if (province) {
    if (th_address_province.find(x => x === province)) {
      return { province, addressArr };
    }
  }
  throw new Error('parse province error');
}

/**
 * Area (อำเภอ, เขต) 923
 * @param {string[]} addressArr 
 */
function parseArea(addressArr) {
  let area = '';

  // method 1 find last index
  const cursor1 = addressArr.length - 1;
  if (cursor1 > -1) {
    const regex1 = new RegExp(/^อำเภอ/);
    const regex2 = new RegExp(/^เขต/);
    const rawArea = addressArr[cursor1].replace(regex1, '').replace(regex2, '');
    const isFound = th_address_area.find(x => x === rawArea);
    if (isFound) {
      area = rawArea;
      addressArr.splice(cursor1, 1);
    } else {
      for (let i = 0; i < addressArr.length; i++) {
        const curAddress = addressArr[i].replace(regex1, '').replace(regex2, '');
        const res = th_address_area.find(x => x === curAddress);
        if (res) {
          area = res;
          addressArr.splice(i, 1);
          break;
        }
      }
    }
  }

  if (area) {
    return { area, addressArr };
  }
  throw new Error('parse area error');
}

/**
 * District (ตำบล, แขวง) 5849
 * @param {string[]} addressArr 
 */
function parseDistrict(addressArr) {
  let district = '';
  // method1 last index
  const cursor1 = addressArr.length - 1;
  if (cursor1 > -1) {
    const regex1 = /^ตำบล/;
    const regex2 = /^แขวง/;
    district = addressArr[cursor1].replace(regex1, '').replace(regex2, '');
    addressArr.splice(cursor1, 1);
  }

  if (district) {
    return { district, addressArr };
  }
  throw new Error('parse district error');
}

export default transfromNL2XLS;
import React from 'react';
import {
  Box,
  Button, Heading, Icon, Modal, ModalBody,
  ModalContent, ModalFooter, ModalOverlay, Stack, Text
} from '@chakra-ui/react';
import { CloseIcon } from '@chakra-ui/icons';

function ModelStatus({ icon, title, text, disclosure }) {
  const { isOpen, onClose } = disclosure;

  return (
    <>
      <Modal onClose={onClose} isOpen={isOpen} isCentered>
        <ModalOverlay />
        <ModalContent>
          <ModalBody>
            <Stack align="center" mt={6}>
              {icon === 'error' && (
                <Icon as={CloseIcon} boxSize={8} color='red.500' mb={4} />
              )}
              {title && (
                <Heading>{title}</Heading>
              )}
              {text && (
                <Text>{text}</Text>
              )}
            </Stack>
          </ModalBody>
          <ModalFooter>
            <Box w="100%">
              <Stack align="center">
                <Button onClick={onClose}>Close</Button>
              </Stack>
            </Box>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
}

export default ModelStatus;
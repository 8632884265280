import {
  Box,
  SimpleGrid,
  Stack,
  Tabs,
  TabList,
  Tab,
  TabPanels,
  TabPanel
} from '@chakra-ui/react';

import TableEmployee from './TableEmployee';
import FetchNL from './FormFetchNL';
import ExportXLS from './ExportXLS';
import ExportSelectedXLS from './ExportSelectedXLS';

function FormLayout() {

  return (
    <Tabs>
      <TabList>
        <Tab>แบบฟอร์ม</Tab>
      </TabList>
      <TabPanels>
        <TabPanel>
          <SimpleGrid minChildWidth='120px' spacing='40px' my={4}>
            <Box>
              <FetchNL />
            </Box>
            <Box>
              <Stack direction="row" align="center" justifyContent="flex-end">
                <ExportXLS />
                <ExportSelectedXLS />
              </Stack>
            </Box>
          </SimpleGrid >
          <Box w="100%">
            <TableEmployee />
          </Box>
        </TabPanel>
      </TabPanels>
    </Tabs>
  );
}

export default FormLayout;
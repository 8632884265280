import { useEffect, useRef } from 'react';

function IndeterminateCheckbox({ indeterminate, ...rest }) {
  const ref = useRef();

  useEffect(() => {
    if (typeof indeterminate === 'boolean') {
      ref.current.indeterminate = !rest.checked && indeterminate;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ref, indeterminate]);

  return (
    <input type='checkbox' ref={ref} {...rest} />
  );
}

export default IndeterminateCheckbox;  
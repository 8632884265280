import React from 'react';

import FormDownload from './components/FormDownload';

function App() {
  return (
    <>
      <FormDownload />
    </>
  );
}

export default App;

import { useContext, useEffect, useState } from 'react';

import {
  Box, Center, Heading, Input, Select, Stack, Text,
} from '@chakra-ui/react';

import {
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  TableContainer,
  Button,
} from '@chakra-ui/react';

import {
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  getPaginationRowModel,
  useReactTable,
} from '@tanstack/react-table';

import IndeterminateCheckbox from './IndeterminateCheckbox';
import context from './FormDownloadContext';

const columnHelper = createColumnHelper();

const columns = [
  {
    id: 'select',
    header: ({ table }) => (
      <IndeterminateCheckbox
        {...{
          checked: table.getIsAllRowsSelected(),
          indeterminate: table.getIsSomeRowsSelected(),
          onChange: table.getToggleAllRowsSelectedHandler(),
        }}
      />
    ),
    cell: ({ row }) => (
      <div className="px-1">
        <IndeterminateCheckbox
          {...{
            checked: row.getIsSelected(),
            disabled: !row.getCanSelect(),
            indeterminate: row.getIsSomeSelected(),
            onChange: row.getToggleSelectedHandler(),
          }}
        />
      </div>
    ),
  },
  columnHelper.accessor(row => row.alcode, {
    id: 'alcode',
    header: () => <span>alcode</span>,
    cell: info => info.getValue(),
  }),
  columnHelper.accessor('alprefixen', {
    header: () => <span>Prefix</span>,
    cell: info => info.renderValue(),
  }),
  columnHelper.accessor('alnameen', {
    header: () => <span>Name</span>,
    cell: info => info.renderValue(),
  }),
  columnHelper.accessor('alsnameen', {
    header: () => <span>surName</span>,
    cell: info => info.renderValue() || '-',
  }),
  columnHelper.accessor('algender', {
    header: () => 'Gender',
    cell: info => info.renderValue(),
  }),
  columnHelper.accessor('albdate', {
    header: () => <span>Birth Date</span>,
    cell: info => info.getValue(),
  }),
  columnHelper.accessor('alnation', {
    header: 'Nation',
    cell: info => info.getValue(),
  }),
];

function TableEmployee() {
  const {
    rawData,
    setSelData,
  } = useContext(context);
  const [rowSelection, setRowSelection] = useState({});

  const table = useReactTable({
    data: rawData?.alienlist || [],
    columns,
    state: {
      rowSelection,
    },
    initialState: {
      pagination: {
        pageSize: 10,
      },
    },
    enableRowSelection: true,
    onRowSelectionChange: setRowSelection,
    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    debugTable: false,
  });

  useEffect(() => {
    const handleSelectionState = (selections) => {
      setSelData((prev) =>
        Object.keys(selections).map(
          (key) =>
            table.getSelectedRowModel().rowsById[key]?.original ||
            prev.find((row) => row._id === key),
        ),
      );
    };

    handleSelectionState(rowSelection);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rowSelection]);

  return (
    <>
      <TableContainer>
        <Table variant="simple">
          <Thead>
            {table.getHeaderGroups().map(headerGroup => (
              <Tr key={headerGroup.id}>
                {headerGroup.headers.map(header => (
                  <Th key={header.id}>
                    {header.isPlaceholder
                      ? null
                      : flexRender(
                        header.column.columnDef.header,
                        header.getContext()
                      )}
                  </Th>
                ))}
              </Tr>
            ))}
          </Thead>
          <Tbody>
            {table.getRowModel().rows.map(row => (
              <Tr key={row.id}>
                {row.getVisibleCells().map(cell => (
                  <Td key={cell.id}>
                    {flexRender(cell.column.columnDef.cell, cell.getContext())}
                  </Td>
                ))}
              </Tr>
            ))}
          </Tbody>
          {/* <tfoot>
        </tfoot> */}
        </Table>
      </TableContainer>
      {rawData?.alienlist?.length ?
        (<Box mt={4}>
          <Stack direction='row' spacing={1} align='center' justifyContent="space-between">
            <Stack direction='row' spacing={1} align='center'>
              <Button
                onClick={() => table.setPageIndex(0)}
                isDisabled={!table.getCanPreviousPage()}
              >{'<<'}</Button>
              <Button
                onClick={() => table.previousPage()}
                isDisabled={!table.getCanPreviousPage()}
              >{'<'}</Button>
              <Button
                onClick={() => table.nextPage()}
                isDisabled={!table.getCanNextPage()}

              >{'>'}</Button>
              <Button
                onClick={() => table.setPageIndex(table.getPageCount() - 1)}
                isDisabled={!table.getCanNextPage()}
              >{'>>'}</Button>
              <Box w="100%">
                <Text noOfLines={1}>หน้า{' '}{table.getState().pagination.pageIndex + 1} จาก{' '}{table.getPageCount()}</Text>
              </Box>
            </Stack>
            <Box>
              <Stack direction='row' spacing={1} align='center'>
                <Stack direction='row' spacing={1} align='center'>
                  <Box w="100%">
                    <Text noOfLines={1}>ไปที่หน้า:{' '}</Text>
                  </Box>
                  <Input type="number" maxW="64px"
                    defaultValue={table.getState().pagination.pageIndex + 1}
                    onChange={e => {
                      const page = e.target.value ? Number(e.target.value) - 1 : 0;
                      table.setPageIndex(page);
                    }}
                  />
                  <Select
                    minW="180px"
                    value={table.getState().pagination.pageSize}
                    onChange={e => {
                      table.setPageSize(Number(e.target.value));
                    }}>
                    {[10, 25, 50, 100].map(pageSize => (
                      <option key={pageSize} value={pageSize}>
                        แสดง {pageSize} รายการ
                      </option>
                    ))}
                  </Select>
                </Stack>
              </Stack>
            </Box>
          </Stack>
        </Box >) :
        <Center minH='240px' color="chakra-subtle-text">
          <Heading textAlign="center" >ยังไม่มีข้อมูล กรุณาระบุ NL-CODE เพื่อดึงข้อมูล</Heading>
        </Center>
      }
    </>
  );
}


export default TableEmployee;
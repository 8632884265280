import { useContext, useEffect, useState } from 'react';
import axios from 'axios';
import { Box, Button, Input, Stack, useDisclosure } from '@chakra-ui/react';
import { v4 as uuidv4 } from 'uuid';

import context from './FormDownloadContext';
// import mock from './data';
import ModelStatus from '../ModelStatus';

const regexNL = /^NL-[0-9]]?[0-9]-[0-9][0-9][0-9][0-9][0-9][0-9]/;

const URL_DEV = 'http://localhost:8080';
const URL_PROD = process.env.REACT_APP_API_URL;
export const API_URL = process.env.NODE_ENV === 'production' ? URL_PROD : URL_DEV;

function FetchNL() {
  const { setNlcode, setRawData, } = useContext(context);
  const [text, setTxet] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [isDisabled, setIsDisabled] = useState(true);
  const [status, setStatus] = useState('');
  const disclosure = useDisclosure();

  useEffect(() => {
    if (regexNL.test(text)) {
      setIsDisabled(false);
      setNlcode(text);
    } else {
      setIsDisabled(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [text]);

  function fetchData() {
    setIsLoading(true);
    axios.get(`${API_URL}/namelist/` + text, {
      headers: {
        Authorization: uuidv4(),
      }
    }).then(async (res) => {
      console.log(res.data);
      if (res?.data) {
        if (res.data?.alienlist) {
          setRawData(res.data);
          return;
        }
      }
      setStatus('');
      disclosure.onOpen();
    }).catch((e) => {
      if (axios.isAxiosError(e)) {
        handleModelError(e);
        console.log(e);
      } else {
        console.log(e);
      }
    })
      .finally(() => {
        setIsLoading(false);
      });
  }

  function handleModelError(e) {
    if (e.response?.data) {
      setStatus(`${e.response.data}`);
    } else {
      setStatus(`${e.response.status} ${e.response.statusText}`);
    }
    disclosure.onOpen();
  }

  const handleChange = (event) => setTxet(event.target.value);

  return (
    <>
      <Stack direction="row">
        <Box maxW="200px">
          <Input
            id="nlcode"
            name="nlcode"
            placeholder='ระบุ NL-Code ที่นี่'
            value={text}
            onChange={handleChange}
          />
        </Box>
        <Button
          isDisabled={isDisabled}
          onClick={fetchData}
          isLoading={isLoading}
          loadingText='กำลังโหลด...'
          colorScheme='blue'
          variant='outline'
        >
          ดึงข้อมูล
        </Button>
      </Stack>
      <ModelStatus title="Oops..." text={status || "Something went wrong!"} icon="error" disclosure={disclosure} />
    </>
  );
}

export default FetchNL;
import { useContext } from 'react';

import transfromNL2XLS from './utils/trasformNL2XLS';
import context from './FormDownloadContext';
import { ExternalLinkIcon } from '@chakra-ui/icons';
import { Button } from '@chakra-ui/react';

const { utils, writeFile } = require('xlsx');
const { useCallback } = require('react');

function ExportXLS() {
  const { nlcode, rawData } = useContext(context);

  const exportFile = useCallback(() => {
    // const ws = utils.aoa_to_sheet(ws_data);

    /* generate worksheet from state */
    const ws = utils.json_to_sheet(transfromNL2XLS(rawData));

    /* create workbook and append worksheet */
    const wb = utils.book_new();
    utils.book_append_sheet(wb, ws, "Sheet1");
    /* export to XLSX */
    writeFile(wb, `${nlcode}.xlsx`);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rawData, nlcode]);

  return (
    <Button
      onClick={exportFile}
      leftIcon={<ExternalLinkIcon />}
      isLoading={false}
      loadingText='Submitting'
      colorScheme='blue'
      variant='outline'
      isDisabled={!rawData?.alienlist}
    >
      ส่งออกทั้งหมด
    </Button>
  );
}

export default ExportXLS;
import { createContext } from 'react';

const FormDownloadContext = createContext({
  nlcode: '',
  // setNlcode: () => { },
  rawData: {},
  // setRawData: () => { },
  selData: [],
  // setSelData: () => { }
});

export default FormDownloadContext;